import * as React from "react"
import {Box, Flex, Text, Image, Button, Heading} from "./responsiveComponents"
import { SectionTitle } from "./title";
import { BoxProps } from "rebass";
import styled from "styled-components";
import {MdPhone} from "react-icons/md";
import IconWhatsapp from "../icons/whatsapp.svg"
import { IconType } from "react-icons/lib/cjs";
import { ExternalLink, PrimaryButton } from "./link";
import { style } from "styled-system";
import { useStaticQuery, graphql } from "gatsby";

const Title = styled(Text)`
text-align: center;
`

interface ButtonIconProps {
    iconType: IconType,
    text: string,
    href?: string
}

const ButtonIcon = (props: ButtonIconProps) => {
    const MiddleIcon = styled(props.iconType)`
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
    `

    return (        
        <PrimaryButton className="hvr-icon-bob" href={props.href} mx={3} my={4} width={208}>                        
            <MiddleIcon className="hvr-icon"  width="1em" height="1em" stroke="currentColor" fill="currentColor" />
             
            <span style={{verticalAlign: "middle"}}>{props.text}</span>            
        </PrimaryButton>
    )
}

const ContactUs = (props: BoxProps) => {
    const {data} = useStaticQuery(
        graphql`
          query {
            data: 
            allAboutYaml {
              nodes {                
                phone {
                  short
                  url
                }
                whatsApp {
                  short
                  url
                }                
              }
            }
          }
        `
      )

    const node = data.nodes[0]

    return (
        <Box {...props} mx="auto" textAlign="center">
            <Title fontSize={5} mb={"vert.5"}>Ready to build your own?</Title>
            <Title color="neutral.6" my={"vert.5"}>Give us a call or send us a message.</Title>     
            <Flex flexDirection="row" alignItems="stretch" justifyContent="center" flexWrap="wrap" mt={"vert.5"}>
                <Box mx={3}>                    
                    <ButtonIcon text={node.phone.short} href={node.phone.url} iconType={MdPhone}/>                    
                </Box>                
                <Box mx={3}>                    
                    <ButtonIcon text={node.whatsApp.short} href={node.whatsApp.url} iconType={IconWhatsapp} />                    
                </Box>
            </Flex>            
        </Box>
    )
}

export default ContactUs