/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title }) {
  const {about} = useStaticQuery(
    graphql`
      query {
        about: 
        allAboutYaml {
          nodes {
            title
            description
            author
            website
            phone {
              long
              url
            }
            whatsApp {
              long
            }
            address {
              street
              city
              region
              postalCode
              country
            }            
          }
        }
      }
    `
  )

  const siteMetadata = about.nodes[0]
  
  const metaDescription = description || siteMetadata.description

  const companyContactSchema = {    
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": siteMetadata.website,
      "contactPoint": [{
        "@type": "ContactPoint",
        "telephone": siteMetadata.phone.long,
        "contactType": "sales",        
      },{
        "@type": "ContactPoint",
        "telephone": siteMetadata.whatsApp.long,
        "contactType": "sales"
      }]
  }

  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "GeneralContractor",
    "image": [
    
    ],
    "@id": siteMetadata.website,
    "name": siteMetadata.title,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": `${siteMetadata.address.street[0]} ${siteMetadata.address.street[1]}`,
      "addressLocality": siteMetadata.address.city,
      "addressRegion": siteMetadata.address.region,
      "postalCode": siteMetadata.address.postalCode,
      "addressCountry": siteMetadata.address.country
    },  
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": -6.139194, 
      "longitude": 106.792278
    },
  "url": siteMetadata.website,
  "telephone": siteMetadata.phone.long,  
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
     
      <script type="application/ld+json">
        {JSON.stringify(companyContactSchema)}
      </script>

      <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>

      <meta name="google-site-verification" content="O3siwTmD7zjrpDNqvdj5W78asK-T1TokbHt0G7IZLdg" />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
