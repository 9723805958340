import * as React from "react"
import {BoxProps} from "rebass"
import {Box, Flex, Text} from "./responsiveComponents"
import Logo from "./logo";
import {NavBar} from './menu'
import {DesktopQuery} from '../theme/themeProvider'
import { Link } from "gatsby";
import { LinkProps } from "./link";



export default class Header extends React.Component<any> {
  render() {    
    const {color, hover, ...rest} = this.props
    return (
      <header>
        <Flex {...rest} alignItems="center">          
          <Box width={[133]}>
            <Link to="/"><Logo /></Link>
          </Box>
          
          <Box mx='auto' />
          <DesktopQuery>
            <NavBar color={color} hover={hover}/>
          </DesktopQuery>
        </Flex>
      </header>
    )
  }
}
