import * as React from "react"
import {
    Card as ReCard,
    Box as ReBox, 
    Flex as ReFlex, 
    Text as ReText, 
    Heading as ReHeading, 
    Link as ReLink, 
    Image as ReImage,
    Button as ReButton,    
    BoxProps, LinkProps, ImageProps, ButtonProps, HeadingProps} from "rebass"
import {space} from "styled-system"
import { withTheme } from "styled-components";
import { isArray, isNumber } from "util";
import {intersection, zip, fill, get} from "lodash"

type elem = number | string

interface FieldKeys extends Object {
    fontSizes: string[],
    space: string[]
}

const spaceField = {
    fontSizes: ['fontSize'], 
    space: ['m', 'mt', 'mb', 'my', 'ml', 'mr', 'mx', 
            'p', 'pt', 'pb', 'py', 'pl', 'pr', 'px']
}

const transformField = (obj : FieldKeys) => {
   return Object.entries(obj).reduce((acc, [key, arr]) => {
        arr.forEach((element: string) => {
            acc.set(element, key)
        });

        return acc;
    }, new Map())
}

const transformed = transformField(spaceField)

const replaceElem = (prefix: string | undefined, value: elem | undefined, object: any) => {    
    if (!prefix) {
        return value;
    }

    if (!value) {
        return object[0];
    }

    if (isNumber(value)) {
        return `${prefix}.${value}`        
    } else if (value.startsWith("vert.")) {
        const replaced = value.replace("vert.", `vert.${prefix}.`);
        return replaced;
    } else {
        return value;
    }
}

const expandArray = (value: elem | elem[], object: any, prefixes: string[]) => {
    let values;

    if (isArray(value)) {
        values = value
    } else {
        values = fill(Array(prefixes.length), value)
    }

    return zip(prefixes, values)
        .map(data => {
            const [prefix, content] = data
            const replacedElem =  replaceElem(prefix, content, object)
            if (get(object, replacedElem)) {
                return replacedElem
            } else {
                return content
            }
        })
}

const replaceProps = (props: any, theme: any) => {
    const intersectionKeys = intersection(Object.keys(props), Array.from(transformed.keys()))

    const prefixes = theme.responsiveAlias;

    const newProps = intersectionKeys.reduce((acc, field) => {
        const value = props[field]
        acc[field] = expandArray(value, theme[transformed.get(field)], prefixes)
        return acc;
    }, {})

    return Object.assign({}, props, newProps)    
}

export function withResponsive(Component: React.ComponentType<BoxProps | LinkProps | ImageProps | ButtonProps | HeadingProps>) {
  
    const Wrapped = (props: any) => {        
      const newProps = replaceProps(props, props.theme)        
      return (        
          <Component {...newProps}/>        
      )
    }  
  
    return Wrapped;
  }

export const Card = withTheme(withResponsive(ReCard))
export const Box = withTheme(withResponsive(ReBox))
export const Flex = withTheme(withResponsive(ReFlex))
export const Text = withTheme(withResponsive(ReText))
export const Heading = withTheme(withResponsive(ReHeading))
export const Link = withTheme(withResponsive(ReLink))
export const Image = withTheme(withResponsive(ReImage))
export const Button = withTheme(withResponsive(ReButton))