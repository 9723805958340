import * as React from "react"
import Menu from 'react-burger-menu/lib/menus/slide'
import {State as MenuState} from "react-burger-menu"
import { Link as GatsbyLink } from "gatsby";
import {Box, Flex} from "./responsiveComponents"
import {theme} from '../theme/themeProvider';
import {TiTimes} from 'react-icons/ti'
import { isNumber } from "util";
import {LinkProps, InternalLink} from "./link"
import styled from "styled-components";

const StyledLink = styled(InternalLink)`
  font-weight: 400;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.07em;
`

const NavLink = (props: LinkProps) => {
  const {href, text, color, hover, onClick} = props  
  

  return (
    <Box ml={6} my={["vert.4", "vert.0"]}>
      <StyledLink to={href} color={color} hovercolor={hover} onClick={onClick}>{text}</StyledLink>
    </Box>  
  )
}

export const NavBar = (props: any) => {
  const {color, hover, onClick, ...rest} = props;  
  return (
    <Flex as="nav" {...rest}>            
        <NavLink href="/" text="Home" color={color} hover={hover} onClick={onClick}/>
        <NavLink href="/#services" text="Our Services" color={color} hover={hover} onClick={onClick}/>
        <NavLink href="/projects" text="Projects" color={color} hover={hover} onClick={onClick}/>
        <NavLink href="/#about"  text="About" color={color} hover={hover} onClick={onClick}/>
        <NavLink href="/#contact" text="Contact" color={color} hover={hover} onClick={onClick}/>
    </Flex>   
  )
}
const px = (num: number | string) => {
  if (isNumber(num)) {
    return num + "px";
  }
  else {
    return num;
  }
}

const styles = {
    bmBurgerBars: {
      background: theme.colors.neutral[4]
    },
    bmBurgerBarsHover: {
      background: theme.colors.primary[7]
    },
    bmBurgerButton: {
      height: "20px", 
      left: "initial",
      position: 'absolute',
      right: px(theme.space[5]),
      top: px(theme.iconSizes[0]),      
      width: "24px",                  
    },
    bmCross: {
      background: theme.colors.primary[1],
      color: theme.colors.neutral[7]
    },       
    bmCrossButton: {
      height: "24px",      
      right: px(theme.space[5]),
      top:  px(theme.iconSizes[0]),      
      width: "24px"
    },
    bmItem: {
      display: 'block'     
    },
    bmItemList: {
      paddingLeft: px(theme.space[4]),
      paddingTop: px(theme.space[4])
    },    
    bmMenu: {
      background: theme.colors.primary[1],
      fontSize: px(theme.fontSizes.sm[3]),
      overflow: 'visible'
    },    
    bmMenuWrap: {
      height: '100%', 
      position: 'fixed',      
      width: '70%'
    },    
    bmMorphShape: {
      fill: '#373a47'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
}

interface MenuWrapperState {
  open: boolean
}

export default class NavBurgerMenu extends React.Component<any, MenuWrapperState> {
  constructor(props:any) {
    super(props)    
    this.state = {open: false}
  }

  handleStateChange (state : MenuState) {
    this.setState({open: state.isOpen})  
  }

  closeMenu () {
    this.setState({open: false})
  }

  render() {
    return (
      <Menu styles={styles} right disableAutoFocus={true} customCrossIcon={<TiTimes />} 
        isOpen={this.state.open}
        onStateChange={(state : MenuState) => this.handleStateChange(state)}>

          <NavBar flexDirection="column" color="neutral.7" mt={7} onClick={() => this.closeMenu()} />
      </Menu>
    )
  }
}