export const outerSpacing = {
    px: [5, 9],
    py: ["vert.12"]
}

export const threeColGrid = {
    width: [1, 0.31]
}

export const twoColGrid = {
    width: [1, 0.46]
}