/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import Header from "./header"
import {BoxProps } from 'rebass'
import {Box, Card} from "./responsiveComponents"
import ThemeProvider, {MobileQuery} from "../theme/themeProvider"
import NavBurgerMenu from "./menu";
import styled from 'styled-components'
import Footer from "./footer";
import 'typeface-sanchez';
import 'typeface-muli';
import { outerSpacing } from "../theme/spacing";
import { light, dark } from "../theme/colors";
import { background } from "styled-system";

export const Container = (props : BoxProps) => (
  <Box
    {...props}
    mx={'auto'}
    my={0}    
  />
)

export function withCenter(Component: React.ComponentType) {
  
  const Wrapped = (props: any) => {
    const {bg, backgroundImage, ...rest} = props;      

    return (
      <Card bg={bg} backgroundImage={backgroundImage}>
        <Component mx="auto" {...rest}/>
      </Card>
    )
  }  

  return styled(Wrapped)`
    max-width: 1300px;
  `
}

const CenteredHeader = withCenter(Header)
const CenteredFooter = withCenter(Footer)

const Layout = ({ children } : any) => (
  <ThemeProvider>        
    <Container width={1}>
      <MobileQuery>
        <NavBurgerMenu />
      </MobileQuery>            
      <CenteredHeader {...light} hover="primary.5" py={"vert.3"} px={outerSpacing.px} id="header"/>
      <main>{children}</main>          
      <CenteredFooter {...outerSpacing} {...dark} id="footer"/>
    </Container>        
  </ThemeProvider> 
)

export default Layout
