import * as React from "react"
import {BoxProps} from "rebass"
import {Box, Flex, Text, Link} from "./responsiveComponents"
import {MdPhone, MdEmail, MdOpenInNew} from "react-icons/md";
import { IconType } from "react-icons/lib/cjs";

import styled from "styled-components";
import IconFb from "../icons/facebook.svg"
import IconInstagram from "../icons/instagram.svg"
import IconWhatsapp from "../icons/whatsapp.svg"
import { threeColGrid } from "../theme/spacing";
import { ExternalLink } from "./link";
import { useStaticQuery, graphql } from "gatsby";


interface ElementProps extends BoxProps {
    iconType: IconType
}

const Element = (props: ElementProps) => {
    const {iconType, children, ...rest} = props
    return (
        <Flex
            {...rest}
            alignItems="flex-start"         
            flexDirection="row"
            my={"vert.1"}>            
            <Box mt={1}>
                <props.iconType width="1.1em" height="1.1em" stroke="currentColor" fill="currentColor"/>
            </Box>
            <Box ml={2}>
                {children}
            </Box>                                  
        </Flex>
    )
}

const SubText = styled(Text).attrs({
    fontSize: 2
})`
    font-weight: 200;
`


const FooterHeading = (props: React.Props<any>) => (
    <Text mb={"vert.1"} fontSize={2} color="neutral.7">{props.children}</Text>
)

const Footer = (props: BoxProps) => {
    const {data} = useStaticQuery(
        graphql`
          query {
            data: 
            allAboutYaml {            
              nodes {     
                title    
                address {
                    street
                    city
                    region
                    postalCode
                    mapsUrl
                }       
                phone {
                  short
                  url
                }
                whatsApp {
                  short
                  url
                } 
                email
                facebook
                instagram               
              }
            }
          }
        `
      )

    const node = data.nodes[0]    

    return (
      <footer>
        <Flex {...props} flexWrap="wrap" alignItems="flex-start" justifyContent="space-between"
            color="neutral.6">
          <Box {...threeColGrid} mb={["vert.4","vert.0"]}>            
            <FooterHeading>{node.title}</FooterHeading>  
            <ExternalLink  href={node.address.mapsUrl}>
                <SubText>
                    {node.address.street[0]}<br/>
                    {node.address.street[1]}<br/>
                    {node.address.city} <br/>
                    {node.address.region} {node.address.postalCode}                                         
                    <span style={{marginLeft: "6px", verticalAlign: "middle"}}><MdOpenInNew color="primary.5" size="1em"/></span>
                </SubText>
                
            </ExternalLink>                       
          </Box>
          <Box {...threeColGrid} mb={["vert.4","vert.0"]}>
            <FooterHeading>Contact</FooterHeading>
            <Element iconType={MdPhone}>
                <ExternalLink href={node.phone.url} color={props.color}>
                    <SubText>{node.phone.short}</SubText>
                </ExternalLink>
            </Element>

            <Element iconType={IconWhatsapp}>
                <ExternalLink  href={node.whatsApp.url} color={props.color}>
                    <SubText>{node.whatsApp.short}</SubText>
                </ExternalLink>
            </Element>

            <Element iconType={MdEmail}>
                <ExternalLink href={`mailto:${node.email}`} color={props.color}>
                    <SubText>{node.email}</SubText>
                </ExternalLink>
            </Element>
          </Box>
          <Box {...threeColGrid}>
            <FooterHeading>Follow Us</FooterHeading>
            <Element iconType={IconFb}>
                <ExternalLink  href={`https://www.facebook.com/${node.facebook}`} color={props.color}>
                    <SubText>{node.facebook}</SubText>
                </ExternalLink>
            </Element>

            <Element iconType={IconInstagram}>
                <ExternalLink  href={`https://www.instagram.com/${node.instagram}`} color={props.color}>
                    <SubText>{node.instagram}</SubText>
                </ExternalLink>
            </Element>            
          </Box> 
        </Flex>
        <Flex px={props.px} flexDirection="column" pb={"vert.2"}>
            <Text color="neutral.4" fontSize={1} textAlign="center">
                Copyright © 2019 {node.title}. All rights reserved.
            </Text>
            <Text color="neutral.4" fontSize={1} textAlign="center">
            Icons made by <ExternalLink href="https://www.freepik.com/" title="Freepik">Freepik </ExternalLink>from <ExternalLink href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</ExternalLink>
            </Text>                    
        </Flex>
      </footer>
    )
}

export default Footer;