import React from "react"
import {groupBy} from "lodash"
import { Box, Text, Flex } from "./responsiveComponents"
import { InternalLink } from "./link"
import Img, { FluidObject } from "gatsby-image"
import { BoxProps } from "rebass";

export interface Project {
    name: string,
    location: string,
    slug: string,
    imageFolder: string,
    year: string,
    type: string,
    category: string  
}

export interface Image {
    relativeDirectory: string,
    childImageSharp: {
        fluid: FluidObject
    }
}

interface Projects {
    projects: Project[],
    images: Image[],
    titleColor: string,
    subtitleColor: string,
    boxProps?: BoxProps,
    childBoxProps?: BoxProps
}

interface ProjectProps {    
    name: string,
    location: string,
    url: string,    
    imageFluid: FluidObject,    
    titleColor: string,
    subtitleColor: string,
    boxProps?: BoxProps
}

const Project = (props: ProjectProps) => {
    const {name, imageFluid, location, url, titleColor, subtitleColor, boxProps} = props
    return (
        <Box {...boxProps}>                
            <InternalLink to={url} color="inherit">
                <Img fluid={imageFluid} className="img-hover-zoom"
                    imgStyle={{transition: "transform .5s ease"}}></Img>
                <Text color={titleColor} mt={"vert.2"}>{name}</Text>
                <Text color={subtitleColor} fontWeight={200}>{location}</Text>
            </InternalLink>                
        </Box>
    )
}

export const ProjectList =  (props: Projects) => {
    const groupedImages = groupBy(props.images, (node) => node.relativeDirectory)    
    const projectsWithImage = props.projects.map(({slug, name, location, imageFolder}) => {
        const key = "projects/" + imageFolder
        const image = groupedImages[key][0].childImageSharp
        const url = "/projects/" + slug

        return (
            <Project
                key={key} 
                name={name} 
                location={location} 
                url={url} 
                imageFluid={image.fluid}
                titleColor={props.titleColor}
                subtitleColor={props.subtitleColor}
                boxProps={props.childBoxProps}
            />
        )
    })    

    return (
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between" {...props.boxProps}>
            {projectsWithImage}
        </Flex>
    )
}