import React from "react"
import styled from "styled-components"
import { Link as GatsbyLink} from 'gatsby';
import { Link, Box } from "./responsiveComponents"
import { Link as RebassLink, LinkProps as RebassLinkProps } from "rebass"
import {
    style,
    space,
    color,
    width,
    height,
    flex,
    order,
    alignSelf,
    flexWrap,
    flexDirection,
    alignItems,
    justifyContent,
    fontSize,
    fontFamily,
    fontWeight,
    textAlign,
    lineHeight,
    letterSpacing,
    borders,
    borderColor,
    borderRadius,
    buttonStyle,
    boxShadow,
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    opacity,
    variant,
  } from 'styled-system'

export interface LinkProps {
    href: string,
    text: string,
    color: string,
    hover: string,
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  }
  
const hovercolor = style({
    cssProperty: 'color',
    key: 'colors',
    prop: 'hovercolor'        
})

export const withStyled = (WrappedComponent : any) => styled(WrappedComponent)`
    text-decoration: none;
    &:hover {
        ${hovercolor}
    };
    ${color}
`

export const InternalLink = withStyled(GatsbyLink)

const TargetBlankLink = (props: RebassLinkProps) => (
    <Link {...props} target="_blank" rel="noopener">
        {props.children}
    </Link>
)

export const ExternalLink = styled(TargetBlankLink)`
    text-decoration: none;    
    color: inherit;
`

const themed = key => props => props.theme[key]

const LinkButton = styled(RebassLink)({
    appearance: 'none',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 'inherit',
    textDecoration: 'none',
  },
    fontWeight,
    borders,
    borderColor,
    borderRadius,
    buttonStyle,
    themed('Button')
  )
  
    
  LinkButton.defaultProps = {
    as: 'a',
    fontSize: 'inherit',
    fontWeight: 'bold',
    m: 0,
    px: 3,
    py: 2,
    color: 'white',
    bg: 'blue',
    border: 0,
    borderRadius: 4,
  }

const hoverbg = style({
    cssProperty: 'background-color',
    key: 'colors',
    prop: 'hoverbg'        
})

const hoverborder = style({
    cssProperty: 'border-color',
    key: 'colors',
    prop: 'hoverborder'        
})

const hovertext = style({
    cssProperty: 'color',
    key: 'colors',
    prop: 'hovertext'        
})

export const PrimaryButton = styled(LinkButton).attrs({    
    px: 3,
    py: 3,
    hoverbg: "primary.3",
    bg: "primary.4"
})`
    &:hover {
        ${hoverbg}
        cursor: pointer;
    }
`

export const OutlineButton = styled(LinkButton).attrs({
    bg: "transparent",
    border: "1px solid", 
    borderRadius: "6px",
    color: "neutral.8",
    hoverborder: "primary.5",
    hovertext: "primary.5",        
    py: 2,
})`
    text-transform: lowercase;
    font-variant: small-caps;
    letter-spacing: 0.07em;
    &:hover {
        /* ${hoverborder}
        ${hovertext}
        ${hoverbg} */
        cursor: pointer;
    }
`