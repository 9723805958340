import React from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import typography from '@styled-system/typography'
import { headings } from 'shevyjs/dist/constants'
import {calcHeadingFontSize} from 'shevyjs/dist/utils'
import range from 'lodash.range'
import Shevy from 'shevyjs'
import {unzip, assignWith, isArray} from 'lodash'


const Global = createGlobalStyle(typography)
const perfectFourth = [3.157, 2.369, 1.777, 1.333, 1, 0.75, 0.563]

const baseTheme = { 
  baseFontScale: perfectFourth,
  addMarginBottom: true,
  proximity: false,
  proximityFactor: 0.85
}

export const mobileScale = new Shevy({
  ...baseTheme,
  baseFontSize: '16px',
  baseLineHeight: 1.5,
});

export const desktopScale = new Shevy({
  ...baseTheme,
  baseFontSize: '18px',
  baseLineHeight: 1.6,
})

const combineToArray = (obj1, obj2) => {
  return assignWith({}, obj1, obj2, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return [...objValue, srcValue]
    } else {
      return [srcValue]
    }    
  })
}

const combinedScaleArray = 
  [...headings,
    'body',
    'content']
  .map(key => {
    return {
      [key]: combineToArray(mobileScale[key], desktopScale[key])
    }
  })

const combinedScale = Object.assign({}, ...combinedScaleArray)
  
const headerFont = {
  fontFamily: "sanchez, serif",
  fontWeight: 700
}

const fontSizes = 
  unzip(
    baseTheme.baseFontScale
    .reverse()
    .map(factor => {
      return [calcHeadingFontSize(mobileScale, factor), calcHeadingFontSize(desktopScale, factor)]
    }) 
  )

const verticalSpaces = 
   unzip(
    range(0, 24)
    .map(index => [mobileScale.baseSpacing(index * 0.25), desktopScale.baseSpacing(index * 0.25)])
   )

export const theme = {   
    space: [
      0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768
    ],
    // fontSizes: [
    //   12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72
    // ],
    fontSizes: {
      sm: fontSizes[0],
      md: fontSizes[1]
    },
    iconSizes: [28],
    responsiveAlias: ['sm', 'md'],
    breakpoints: [768, 1025, 1281],
    colors: {      
      primary: [
        'hsl(208, 95%, 12%)',
        'hsl(208, 86%, 19%)', 
        'hsl(208, 79%, 28%)', 
        'hsl(208, 75%, 36%)', 
        'hsl(208, 76%, 54%)',
        'hsl(208, 85%, 66%)',
        'hsl(208, 90%, 76%)',
        'hsl(208, 97%, 84%)',
        'hsl(210, 100%, 92%)'
      ],
      neutral: [
        'hsl(219, 20%, 7%)',
        'hsl(219, 20%, 11%)',
        'hsl(219, 20%, 16%)',
        'hsl(219, 20%, 28%)',
        'hsl(219, 20%, 40%)',
        'hsl(219, 20%, 50%)',
        'hsl(219, 20%, 61%)',
        'hsl(219, 20%, 72%)',
        'hsl(219, 40%, 95%)'
      ],
      accents: [
        
      ],
      white: "#FFF"
    },

    fonts: {
      header: headerFont.fontFamily,
      body: "muli, sans-serif"
    },
    
    typography: {
      body: {
        ...combinedScale.body,
        fontFamily:"body"                
      },
      h1: {
        ...headerFont,
        ...combinedScale.h1
      },
      h2: {
        ...headerFont,
        ...combinedScale.h2
      },
      h3: {
        ...headerFont,
        ...combinedScale.h3
      },
      h4: {
        ...headerFont,
        ...combinedScale.h4
      }, 
      h5: {
        ...headerFont,
        ...combinedScale.h5
      }
    }
  }

theme.space.vert = {
  sm: verticalSpaces[0],
  md: verticalSpaces[1]
}

export const MobileQuery = styled.div`
  display: none;

  @media (max-width: ${theme.breakpoints[0]-1}px) {
    display: block;
  };
`

export const DesktopQuery = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints[0]}px) {
    display: block;
  };
`

export default props => (
    <ThemeProvider theme={theme}> 
        <>
          <Global />
          {props.children}
        </> 
    </ThemeProvider>
)